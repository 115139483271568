<template>
  <v-dialog v-model="modalView" :width="CWidth">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title>Detail Kapabilitas APIP</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="px-5 py-5">
          <v-row class="mb-5">
            <v-col cols="8">
              <v-card outlined height="572" class="mt-2">
                <iframe
                  :src="getPDF(viewItem.apip_file)"
                  type="application/pdf"
                  height="100%"
                  width="100%"
                ></iframe>
              </v-card>
            </v-col>

            <v-col cols="4">
              <v-col cols="12" class="mb-n10">
                <span class="subtitle-2">Nama apip</span>
                <v-text-field
                  dense
                  flat
                  outlined
                  v-model="viewItem.apip_nama"
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <span class="subtitle-2">Keterangan apip</span>
                <v-textarea
                  dense
                  flat
                  outlined
                  v-model="viewItem.apip_ket"
                  readonly
                ></v-textarea>
              </v-col>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import modalView from "@/store/informasi/apip/modalView";

export default {
  created() {
    if (
      this.$vuetify.breakpoint.name === "xs" ||
      this.$vuetify.breakpoint.name === "sm"
    ) {
      this.CWidth = "100%";
    }
  },

  computed: {
    modalView: {
      get() {
        return modalView.state.modalView;
      },
      set(value) {
        modalView.commit("toggleModal", value);
      },
    },

    viewItem: {
      get() {
        return modalView.state.apip;
      },
      set(value) {
        console.log(value);
      },
    },
  },

  data: () => ({
    CWidth: "70%",
  }),

  methods: {
    getPDF(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/apipFile/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/apipFile/pdf-default.pdf";
      }
    },

    closeModal() {
      this.modalView = false;
    },
  },
};
</script>
